<template>
  <BaseCard
    ref="base-card"
    collapsable
    :footer-visible="false"
  >
    <template #title>
      <b-row
        align-v="center"
        align-h="between"
      >
        <b-col
          class="font-weight-bold d-flex align-items-center"
          cols="auto"
        >
          <span class="text-uppercase">condiciones</span>

        </b-col>
        <b-col>
          <b-badge
            v-if="overdueBillsBadgeVisible"
            pill
            variant="light-danger"
            class="d-inline-flex align-items-center"
          >
            <feather-icon
              icon="InboxIcon"
              size="25"
              color="danger"
            />
            <span class="text-capitalize font-weight-light text-danger badge-text"> 
              facturas pendientes / vencidas 
            </span>
          </b-badge>
        </b-col>
      </b-row>
    </template>
    <b-row class="p-2">
      <b-col sm="5">
        <b-row>
          <b-col cols="6">
            <div class="font-weight-bold">
              Hitos de facturación:
            </div>
            <div class="text-light">
              {{ billingMilestone }}
            </div>
          </b-col>
          <b-col cols="6">
            <div class="font-weight-bold">
              Avales y garantías:
            </div>
            <div class="text-light">
              {{ endorsementsAndGuarantees }}
            </div>
          </b-col>
          <b-col cols="6">
            <div class="font-weight-bold">
              Formas de pago:
            </div>
            <div class="text-light">
              {{ typePayment }}
            </div>
          </b-col>
          <b-col cols="6">
            <div class="font-weight-bold">
              Condiciones de retenciones:
            </div>
            <div class="text-light">
              {{ withholdingConditions }}
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="7">
        <div class="font-weight-bold">
          Estado de riesgo cliente
        </div>
        <b-table
          responsive
          :fields="columns"
          :items="customerRiskStatusData"
          :bordered="true"
        />
      </b-col>
    </b-row>
  </BaseCard>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  name: 'JobConditionsCard',
  components: { BaseCard },
  data() {
    return {
      columns: [
        {
          label: 'LÍMITE', key: 'limit', thStyle: { width: '33%', 'text-align': 'right' }, tdClass: 'text-right',
        },
        {
          label: 'FACTURACIÓN VIVA', key: 'live_billing', thStyle: { width: '33%', 'text-align': 'right' }, tdClass: 'text-right',
        },
        {
          label: 'DISPONIBLE', key: 'available', thStyle: { width: '33%', 'text-align': 'right' }, tdClass: 'text-right',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('job', {
      job: 'getJob',
    }),
    typePayment() {
      return this.job?.type_payment?.name || 'Sin formas de pago'
    },
    withholdingConditions() {
      return this.job?.withholding_conditions || 'Sin condiciones de retención'
    },
    endorsementsAndGuarantees() {
      return this.job?.endorsements_and_guarantees || 'Sin avales ni garantías'
    },
    billingMilestone() {
      return this.job?.billing_milestone || 'Sin hitos de facturación'
    },
    customerRiskStatusData() {
      if (!this.job?.customer_risk_status) {
        return []
      }

      const result = {}
      Object.keys(this.job.customer_risk_status).forEach(key => {
        result[key] = `${this.$options.filters.numberToLocalString(this.job.customer_risk_status[key])} €`
      })

      return [result]
    },
    overdueBillsBadgeVisible() {
      return this.job?.customer_risk_status?.overdue_bills
    },
  },
  methods: {
    setCollapse(collapse) {
      this.$refs['base-card'].setCollapse(collapse)
    },
  },
}
</script>
