<template>
  <div>
    <BaseTable
      ref="certifications-table"
      :columns="columns"
      :totals-configuration="totalsConfiguration"
      :resource="$data.$constants.RESOURCES.RESOURCE_CERTIFICATIONS"
      :resourceStore="'certifications'"
      :loading="loading"
      :filters="baseFilters"
      :hide-toolbar="hideToolbar"
      :disable-pagination="disablePagination"
      :initial-order-by="initialOrderBy"
      @data-loaded="$emit('data-loaded', $event)"
      @filter-button-click="filtersVisible = !filtersVisible"
      @on-clear-active-filters="handleClearListFilters"
    >
      <template #cell(certification_date)="data">
        <b-form-datepicker
          v-if="rowsEditionEnabled[data.index]"
          v-model="data.item.certification_date"
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          placeholder=""
          size="sm"
          reset-button
          :hide-header="true"
          :label-help="null"
          boundary="viewport"
          start-weekday="1"
        />
        <span v-else>{{ data.value | formatDate }} </span>
      </template>
      <template #cell(job)="data">
        {{ data.value ? data.value.code : "" }}
      </template>
      <template #cell(client)="data">
        <p>{{ data.value ? data.value.name : "" }}</p>
        <p>
          <b-form-input
            v-if="rowsEditionEnabled[data.index]"
            v-model="data.item.observations"
            placeholder="Observaciones"
            id="observations"
          />
        </p>
      </template>
      <template #cell(total_sale_offer)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(certificate_of_origin)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(certificate_of_month)="data">
        <b-form-input
          v-if="rowsEditionEnabled[data.index]"
          v-model="data.item.certificate_of_month"
          type="number"
        />
        <span v-else>{{ data.value | numberToLocalString }} €</span>
      </template>
      <template #cell(previous_certificate)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(status)="data">
        <StatusSelect
          v-if="rowsEditionEnabled[data.index]"
          v-model="data.item.status"
          class="font-small-3 d-inline-block"
          :clearable="false"
          :type="statusType"
        />
        <StatusBadge
          v-else-if="data.item.status"
          :status="data.item.status.name"
        />
      </template>
      <template #cell(documentation)="data">
        <div
          v-if="data.item.documentation && data.item.documentation.length > 0"
          v-for="document in data.item.documentation"
          class="d-flex justify-content-end align-items-center pt-50"
        >
          <b-link
            class="text-indigo pr-1"
            @click="handleDownloadDocument(document)"
          >
            {{ document.name }}
          </b-link>
          <b-link
            @click="
              handleDeleteDocument(data.item.id, document.id)
            "
          >
            <span class="text-danger">
              <feather-icon
                v-b-tooltip.hover
                title="Eliminar"
                icon="TrashIcon"
                size="18"
              />
            </span>
          </b-link>
        </div>
      </template>
      <template #cell(actions)="data">
        <feather-icon
          v-show="data.item.observations"
          :id="`popover-target-${data.item.id}`"
          icon="InfoIcon"
          size="18"
          class="text-indigo mr-1"
        />
        <b-popover
          :target="`popover-target-${data.item.id}`"
          triggers="hover"
          placement="top"
        >
          <template #title>Observaciones</template>
          <pre style="background: #ffff">{{ data.item.observations }}</pre>
        </b-popover>
        <template v-if="data.item.status.name !== 'Facturada'">
          <b-link
            v-if="!rowsEditionEnabled[data.index]"
            class="d-inline-block text-indigo pr-1"
            @click="handleEditIconClick(data)"
          >
            <feather-icon
              v-b-tooltip.hover
              title="Editar"
              icon="Edit2Icon"
              size="18"
            />
          </b-link>
          <template v-else>
            <b-link
              class="d-inline-block text-indigo pr-50"
              @click="handleSaveCertificationIconClick(data.item, data.index)"
            >
              <feather-icon
                v-b-tooltip.hover
                title="Guardar certificacion"
                icon="SaveIcon"
                size="18"
              />
            </b-link>
            <b-link
              class="d-inline-block text-danger pr-1"
              @click="handleCancelEditionIconClick(data)"
            >
              <feather-icon
                v-b-tooltip.hover
                title="Cerrar"
                icon="SlashIcon"
                size="18"
              />
            </b-link>
            <BaseFileInput
              ref="jod-additional-file-input"
              class="d-inline"
              :multiple="false"
              @change="handleUpdateFile($event, data.item, data.index)"
            >
              <b-link class="d-inline-block text-indigo pr-1">
                <feather-icon icon="UploadIcon" size="18" />
              </b-link>
            </BaseFileInput>
          </template>
        </template>
        <template>
          <b-link
            v-access="{
              resource: $data.$constants.RESOURCES.RESOURCE_CERTIFICATIONS,
              resourceAction:
                $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL,
            }"
            v-if="!isCertificationInvoiced(data.item)"
            class="d-inline-block text-danger pr-1"
            @click="handleCertificationDeleteIconClick(data.item)"
          >
            <feather-icon
              v-b-tooltip.hover
              title="Eliminar"
              icon="TrashIcon"
              size="18"
            />
          </b-link>
        </template>
        <b-link
          v-if="isCertificationInvoiced(data.item)"
          :to="{
            name: 'viewSaleInvoice',
            params: { id: data.item.sale_invoice.id },
          }"
        >
          <b-button
            variant="outline-primary"
            class="text-nowrap rounded-sm px-2 bill-button"
          >
            Ver factura
          </b-button>
        </b-link>
        <b-link
          v-if="
            isCertificationPendingBilling(data.item) &&
            canAccessResource(
              $data.$constants.RESOURCES.RESOURCE_SALE_INVOICES,
              RESOURCE_ACTION_ALL
            )
          "
        >
          <b-button
            variant="primary"
            class="text-nowrap rounded-sm px-2 bill-button mt-2"
            @click="handleInvoiceButtonClick(data.item)"
          >
            Facturar
          </b-button>
        </b-link>
      </template>
    </BaseTable>
    <CertificationsListFilters
      ref="certifications-list-filters"
      v-model="filtersVisible"
      @filters-submit="$store.commit('certifications/setFilters', $event)"
    />
  </div>
</template>

<script>
import { CERTIFICATIONS_STATUS_TYPE } from "@/api/status-api";
import ApiRestService from "@/api/base-api";
import CertificationsApi from "@/api/certifications-api";
import BaseFileInput from "@/components/ui/file/BaseFileInput.vue";
import Vue from "vue";
import BaseTable from "@/components/ui/table/BaseTable.vue";
import StatusBadge from "@/components/status/badge/StatusBadge.vue";
import StatusSelect from "@/components/status/select/StatusSelect.vue";
import CertificationsListFilters from "@/components/certifications/filters/CertificationsListFilters.vue";
import { mapGetters } from "vuex";
import { RESOURCE_ACTION_ALL } from "@/shared/constants/resourceActions";

export default {
  name: "CertificationsTable",
  components: {
    CertificationsListFilters,
    StatusSelect,
    StatusBadge,
    BaseTable,
    BaseFileInput,
  },
  props: {
    jobId: {
      type: String,
      default: null,
    },
    hideToolbar: {
      type: Boolean,
      default: false,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    initialOrderBy: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      RESOURCE_ACTION_ALL,
      rowsEditionEnabled: [],
      totalsConfiguration: [
        { colspan: 7 },
        { label: "CERTIFICADO", key: "certified", unit: "€" },
        { label: "PENDIENTE DE FACTURAR", key: "pending", unit: "€" },
      ],
      loading: false,
      filtersVisible: false,
      statusType: CERTIFICATIONS_STATUS_TYPE,
    };
  },
  computed: {
    ...mapGetters({
      filters: "certifications/getFilters",
      canAccessResource: "auth/canAccessResource",
    }),
    columns() {
      const result = [
        {
          label: "FECHA",
          key: "certification_date",
          sortable: true,
          thStyle: { "min-width": "150px" },
        },
      ];

      if (!this.jobId) {
        result.push({
          label: "JOB",
          key: "job",
          sortable: true,
          thStyle: { "min-width": "200px" },
        });
      }

      return result.concat([
        {
          label: "",
          key: "client",
          sortable: true,
        },
        {
          label: "OFERTA",
          key: "total_sale_offer",
          thClass: "text-right",
          tdClass: "text-right",
          sortable: true,
          thStyle: { "min-width": "120px" },
        },
        {
          label: "CERTIFICADO A ORIGEN",
          key: "certificate_of_origin",
          thClass: "text-right",
          tdClass: "text-right",
          sortable: true,
        },
        {
          label: "CERTIFICADO ANTERIOR",
          key: "previous_certificate",
          thClass: "text-right",
          tdClass: "text-right",
          sortable: true,
        },
        {
          label: "CERTIFICADO MES",
          key: "certificate_of_month",
          thClass: "text-right",
          tdClass: "text-right",
          sortable: true,
        },
        {
          label: "ESTADO",
          key: "status",
          sortable: true,
          thStyle: { "min-width": "260px" },
        },
        {
          label: "DOCUMENTACION",
          key: "documentation",
          thClass: "text-right",
          thStyle: { "min-width": "240px" },
        },
        {
          label: "",
          key: "actions",
          thStyle: { "min-width": "260px" },
          tdClass: "text-right",
        },
      ]);
    },
    baseFilters() {
      return { job_id: this.jobId, ...this.filters };
    },
  },
  methods: {
    isCertificationInvoiced(certification) {
      return (
        certification.status.name === "Facturada" && certification.sale_invoice
      );
    },
    isLocked(data) {
      return data.locked === "1" ? false : true;
    },
    isCertificationPendingBilling(certification) {
      return certification.status.name === "Pendiente de facturar";
    },
    handleClearListFilters() {
      this.$store.commit("certifications/setFilters", {});
      this.$refs["certifications-list-filters"].clearFilters();
    },
    async handleInvoiceButtonClick(certification) {
      this.loading = true;
      try {
        await CertificationsApi.createSaleInvoice(certification.id);
        await this.loadData();
      } finally {
        this.loading = false;
      }
    },
    loadData() {
      return this.$refs["certifications-table"].loadData();
    },
    async handleSaveCertificationIconClick(certification, index) {
      this.loading = true;
      try {
        await CertificationsApi.edit(certification.id, certification, false);
        await this.loadData();
        this.rowsEditionEnabled[index] = false;
        this.$toast("Recurso actualizado con éxito.");
      } catch (error) {
        this.handleSaveCertificationError(error);
      }
      this.loading = false;
    },
    handleSaveCertificationError(error) {
      if (error.response.data.certificate_of_month) {
        this.$toast.error(error.response.data.certificate_of_month[0]);
        return;
      }

      this.$toast.error(
        "Ocurrió un error al actualizar el recurso. Por favor inténtelo de nuevo mas tarde."
      );
    },
    async handleCertificationDeleteIconClick(certification) {
      const response = await this.$modal.show({
        title: "¿Está seguro?",
        text: "Se borrará el registro.",
      });

      if (!response.isConfirmed) {
        return;
      }

      this.loading = true;
      try {
        await CertificationsApi.delete(certification.id);
        await this.loadData();
      } finally {
        this.loading = false;
      }
    },
    handleEditIconClick(row) {
      Vue.set(this.rowsEditionEnabled, row.index, true);
    },
    async handleCancelEditionIconClick(row) {
      this.loading = true;
      try {
        await this.loadData();
        Vue.set(this.rowsEditionEnabled, row.index, false);
      } finally {
        this.loading = false;
      }
    },
    handleUpdateFile(file, certification, rowIndex) {
      this.handleSaveCertificationIconClick(
        { ...certification, documentation: file },
        rowIndex
      );
    },
    async handleDeleteDocument(certificationId, fileId) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: `El documento se borrará permanentemente`,
      })

      if (!response.isConfirmed) {
        return
      }


      this.loading = true;
      try {
        await CertificationsApi.deleteDocument(certificationId, fileId);
        await this.loadData();
      } finally {
        this.loading = false;
      }
    },
    async handleDownloadDocument(document) {
      if (!document || document.length === 0) {
        return;
      }

      this.loading = true;
      try {
        await ApiRestService.downloadArchive(document.id, document.name);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
/* [FIX] soluciona el error del date picker que no se muestra completo */
::v-deep .table {
  position: static !important;
}
.bill-button {
  width: 140px;
}
</style>
